var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { overflow, backgroundColor as systemBackgroundColor, width, } from 'styled-system';
import { useLoadingImageBackground } from '../../hooks/image';
import { useIsObserved } from '../../hooks/intersection-observer';
import { objectFit, textIndent, whiteSpace, } from '../../theme/system-utilities';
import { getDimensions } from '../../utils/cdn/image';
import { captureError } from '../../utils/sentry';
import { ThemedMedia } from '../Media';
import { defaultPreloadImage, getCurrentPreloadId, getSourceFromRatio, } from './utils';
var Image = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), systemBackgroundColor, width, textIndent, whiteSpace, overflow, objectFit);
Image.defaultProps = {
    textIndent: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    objectFit: 'cover',
};
var useLazyImage = function (imageSizes, preloadImage) {
    var _a = __read(useState(false), 2), loaded = _a[0], setLoaded = _a[1];
    var preloadId = useRef(null);
    var _b = __read(useState(''), 2), imageSrc = _b[0], setImageSrc = _b[1];
    var _c = __read(useIsObserved(), 2), ref = _c[0], isObserved = _c[1];
    var _d = useLoadingImageBackground(loaded), backgroundColor = _d.backgroundColor, onImageLoad = _d.onImageLoad;
    useEffect(function () {
        var isAlive = true;
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var src, currentPreloadId, preloadedSrc, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isObserved) return [3 /*break*/, 4];
                        src = getSourceFromRatio(imageSizes);
                        currentPreloadId = getCurrentPreloadId(src);
                        preloadId.current = currentPreloadId;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, preloadImage(src)];
                    case 2:
                        preloadedSrc = _a.sent();
                        if (isAlive && preloadId.current === currentPreloadId) {
                            setLoaded(true);
                            setImageSrc(preloadedSrc);
                            preloadId.current = null;
                            onImageLoad();
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        captureError("Failed to load image. ".concat(err_1));
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); })();
        return function () {
            if (preloadId.current) {
                preloadId.current = null;
            }
            isAlive = false;
        };
    }, [isObserved]);
    return { loaded: loaded, imageSrc: imageSrc, backgroundColor: backgroundColor, isObserved: isObserved, ref: ref };
};
var LazyImage = function (_a) {
    var thumbnail = _a.thumbnail, x1 = _a.x1, x2 = _a.x2, x3 = _a.x3, children = _a.children, _b = _a.preloadImage, preloadImage = _b === void 0 ? defaultPreloadImage : _b, imgProps = __rest(_a, ["thumbnail", "x1", "x2", "x3", "children", "preloadImage"]);
    var imageSizes = { thumbnail: thumbnail, x1: x1, x2: x2, x3: x3 };
    var placeholderSize = getDimensions(x1);
    var _c = useLazyImage(imageSizes, preloadImage), loaded = _c.loaded, imageSrc = _c.imageSrc, backgroundColor = _c.backgroundColor, isObserved = _c.isObserved, ref = _c.ref;
    if (children) {
        return children({
            loaded: loaded,
            image: imageSrc,
            intersected: isObserved,
            thumbnail: thumbnail,
            innerRef: ref,
        });
    }
    return (React.createElement(Image, __assign({}, ((placeholderSize === null || placeholderSize === void 0 ? void 0 : placeholderSize.width) && { width: placeholderSize.width }), imgProps, { loading: isObserved ? 'eager' : 'lazy', src: loaded ? imageSrc : thumbnail, ref: ref, backgroundColor: backgroundColor, "aria-live": "polite", "aria-busy": !loaded && isObserved })));
};
var ResponsiveLazyImage = function (props) {
    var x1 = props.x1, x2 = props.x2, x3 = props.x3, mobile = props.mobile, tablet = props.tablet, desktop = props.desktop, large_desktop = props.large_desktop, preloadImage = props.preloadImage, restProps = __rest(props, ["x1", "x2", "x3", "mobile", "tablet", "desktop", "large_desktop", "preloadImage"]);
    if (x1 && x2 && x3) {
        // Support for old responsive image format
        // that was working only for dpr
        return (React.createElement(LazyImage, __assign({ x1: x1, x2: x2, x3: x3, preloadImage: preloadImage }, restProps)));
    }
    else if (mobile && tablet && desktop) {
        // New responsive image format that supports
        // both dpr and main breakpoints
        return (React.createElement(ThemedMedia, null, function (matches) {
            var imageProps = matches.large_desktop && large_desktop
                ? large_desktop
                : matches.desktop
                    ? desktop
                    : matches.tablet
                        ? tablet
                        : mobile;
            return (React.createElement(LazyImage, __assign({ preloadImage: preloadImage }, imageProps, restProps)));
        }));
    }
    else {
        // eslint-disable-next-line no-console
        console.warn('<ResponsiveLazyImage /> is missing required props');
        return null;
    }
};
var ResponsiveLazyImageMemo = React.memo(ResponsiveLazyImage);
export { LazyImage, ResponsiveLazyImage, ResponsiveLazyImageMemo };
var templateObject_1;
