import { isClient } from '../../utils/isClient';
export var getSourceFromRatio = function (images) {
    if (!isClient()) {
        return images.x1;
    }
    var cappedRatio = Math.min(3, Math.max(1, Math.ceil(window.devicePixelRatio)));
    return images["x".concat(cappedRatio)] || images.x1;
};
export var defaultPreloadImage = function (src) {
    return new Promise(function (resolve, reject) {
        var img = document.createElement('img');
        img.onload = function () { return resolve(src); };
        img.onerror = function () { return reject(new Error("Failed to preload ".concat(src))); };
        img.src = src;
    });
};
export var getCurrentPreloadId = function (src) {
    return Symbol("LazyImage ".concat(src, " preload-id"));
};
